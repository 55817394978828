import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5749eca9 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _09106481 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _563b7b94 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _7bdf4a19 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _009f6426 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _f13fb3ac = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _9cf0c08e = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _180728c5 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _19a70a76 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _6c41d2e4 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _3ff13f04 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _298887c9 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _349bcff4 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _e51cb4c8 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _0f12fe8a = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _7b2eeb65 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _3d7799af = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _709bdeb6 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _519cd26f = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _38a21b40 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _5749eca9,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _09106481,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _563b7b94,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _7bdf4a19,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _009f6426,
    name: "receipt"
  }, {
    path: "/search",
    component: _f13fb3ac,
    name: "search"
  }, {
    path: "/search-booking",
    component: _9cf0c08e,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _180728c5,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _19a70a76,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _6c41d2e4,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _3ff13f04,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _298887c9,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _349bcff4,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _e51cb4c8,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _0f12fe8a,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _7b2eeb65,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _3d7799af,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _709bdeb6,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _519cd26f,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _38a21b40,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
